import {
  UserDispatchTypes,
  USER_UNAUTHENTICATED,
  USER_AUTHENTICATED,
  USER_LOADING,
} from "../actions/UserActions/UserActions.types";

interface DefaultStateInterface {
  loading: boolean;
  isAuthenticated: boolean;
  email?: string;
  id?: string;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  isAuthenticated: false,
};

const shipReducer = (
  state: DefaultStateInterface = defaultState,
  action: UserDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case USER_UNAUTHENTICATED:
      return {
        ...state,
        loading: false,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_AUTHENTICATED:
      return {
        ...state,
        loading: false,
        isAuthenticated: action.payload.isAuthenticated,
        email: action.payload.email,
      };
    default:
      return state;
  }
};

export default shipReducer;
