import React, { useState, useEffect, useLayoutEffect } from "react";
import { Navigate, Route, useLocation } from "react-router-dom";

import WithLoader from "../components/molecules/WithLoader/WithLoader.component";
import { verifyToken } from "../utils/verifyToken";
import { useDispatch } from "react-redux";
import { isUserAuthenticated } from "../actions/UserActions/UserActions";
import { API_URL } from "../utils/env";

interface LoginRouteInterface {
  Component: React.FC<{}>;
}

const LoginRoute: React.FC<LoginRouteInterface> = ({
  Component: Component,
}) => {
  const [verifiedToken, setVerifiedToken] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (!sessionStorage.getItem("errorMessage")) {
        if (localStorage.getItem("token")) {
          // Comment out verityToken to check token validation
          verifyToken(setVerifiedToken, setLoading, API_URL + "/");
          setVerifiedToken(true);
          dispatch(isUserAuthenticated(true) as any);
        } else {
          // Set setVerifiedToken to false to check token validation
          verifyToken(setVerifiedToken, setLoading, API_URL + "/");
          setVerifiedToken(false);
          dispatch(
            isUserAuthenticated(
              false,
              localStorage.getItem("email") as any
            ) as any
          );
        }
      } else {
        verifyToken(setVerifiedToken, setLoading, API_URL + "/");
      }
    }, 100);
  }, [location.pathname]);

  if (isLoading) {
    return <WithLoader />;
  }
  return verifiedToken ? (
    <>
      <Navigate
        to={{
          pathname: "/",
        }}
      />
      <Component />
    </>
  ) : (
    <>
      <Component />
    </>
  );
};

export default LoginRoute;
