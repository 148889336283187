import React, { useState, useEffect } from "react";
import WithLoader from "../components/molecules/WithLoader/WithLoader.component";
import { useLocation, Route, Navigate } from "react-router-dom";
import { routes } from "./routes";
import { verifyToken } from "../utils/verifyToken";
import { useDispatch } from "react-redux";
import { isUserAuthenticated } from "../actions/UserActions/UserActions";
import { API_URL } from "../utils/env";

export interface PrivateRouteInterface {
  Component: React.FC<{}>;
}

const PrivateRoute: React.FC<PrivateRouteInterface> = ({ Component }) => {
  const [verifiedToken, setVerifiedToken] = useState<boolean | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      if (!sessionStorage.getItem("errorMessage")) {
        if (localStorage.getItem("token")) {
          // Comment out verityToken to check token validation
          verifyToken(setVerifiedToken, setLoading, API_URL + "/");
          setVerifiedToken(true);
          dispatch(
            isUserAuthenticated(
              true,
              localStorage.getItem("email") as any
            ) as any
          );
        } else {
          // Set setVerifiedToken to false to check token validation
          setVerifiedToken(false);
          dispatch(isUserAuthenticated(false) as any);
        }
      } else {
        verifyToken(setVerifiedToken, setLoading, API_URL + "/");
      }
    }, 100);
  }, [location.pathname]);

  useEffect(() => {
    if (verifiedToken) {
      dispatch(isUserAuthenticated(true) as any);
      sessionStorage.removeItem("errorMessage");
    } else {
      dispatch(isUserAuthenticated(false) as any);
    }
  }, [verifiedToken]);

  return verifiedToken === null ? (
    <WithLoader isLoading={true} />
  ) : verifiedToken ? (
    <>
      <Component />
    </>
  ) : (
    <>
      <Navigate to={routes.landingPage} />
    </>
  );
};

export default PrivateRoute;
