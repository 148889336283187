import React from "react";
import Spinner from "../../atoms/Spinner/Spinner.component";
import { SpinnerInterface } from "../../atoms/Spinner/Spinner.component";
import { Wrapper } from "./WithLoader.styles";

export interface WithLoaderInterface extends SpinnerInterface {
  children?: React.ReactNode;
}

const WithLoader: React.FC<WithLoaderInterface> = ({
  children,
  isLoading,
  ...args
}) => (
  <div>
    {isLoading ? (
      <Wrapper>
        <Spinner {...args} />
      </Wrapper>
    ) : (
      children
    )}
  </div>
);

export default WithLoader;
