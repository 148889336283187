export const RatingItemPartials = {
	RatingItemPartials: {
		width: {
			full: '100%',
			regular: '15rem',
			big: '16rem',
		},
		height: {
			tab: 15,
			smallTab: 9.2,
			details: 13.6,
			scoringList: 10.2,
			big: 16,
		},
	},
};

export default RatingItemPartials;
