import { Dispatch } from "redux";
import {
  UserDispatchTypes,
  USER_UNAUTHENTICATED,
  USER_AUTHENTICATED,
  USER_LOADING,
} from "./UserActions.types";

export const isUserAuthenticated =
  (isAuthenticated: boolean, email?: string) =>
  (dispatch: Dispatch<UserDispatchTypes>) => {
    try {
      dispatch({
        type: USER_LOADING,
      });
      if (isAuthenticated) {
        dispatch({
          type: USER_AUTHENTICATED,
          payload: { isAuthenticated: isAuthenticated, email: email },
        });
      } else {
        dispatch({
          type: USER_UNAUTHENTICATED,
        });
      }
    } catch (e) {
      dispatch({
        type: USER_UNAUTHENTICATED,
      });
    }
  };
