import React from "react";
import { useLocation } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { Wrapper } from "./ContentHandler.styles";

export interface ContentHandlerInterface {
  children?: React.ReactNode;
  pathname?: string;
  isLandingPage?: boolean;
}

export const whiteBackgroundPages = [routes.homepage];

const ContentHandler: React.FC<ContentHandlerInterface> = ({ children }) => {
  const { pathname } = useLocation();
  return <Wrapper pathname={pathname}>{children}</Wrapper>;
};

export default ContentHandler;
