const IconsTheme = {
	icons: {
		search: '\\e905',
		search_thin: '\\e904',
		logout: '\\e9b6',
		ship: '\\e902',
		podium: '\\e8ff',
		goal: '\\e903',
		printer: '\\e954',
		pdf: '\\e906',
		csv: '\\e901',
		exit: '\\907',
	},
};

export default IconsTheme;
