import styled from "styled-components";
import { routes } from "../../../routes/routes";
import {
  ContentHandlerInterface,
  whiteBackgroundPages,
} from "./ContentHandler.component";

export const Wrapper = styled.main<ContentHandlerInterface>`
  height: ${({ isLandingPage, pathname }) =>
    pathname &&
    (pathname.includes(routes.landingPage) ||
      pathname.includes(routes.resetPassword))
      ? "100%"
      : "unset"};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme, pathname }) =>
    pathname && whiteBackgroundPages.includes(`/${pathname.split("/")[1]}`)
      ? theme.color.white
      : theme.color.appBackgroundGrey};
`;
