import { createGlobalStyle } from "styled-components";
import theme from "./MainTheme";

const MainTheme = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 0;
        margin: 0;
        outline: none;
    }

    a {
        color: var(--black);
        text-decoration: none;
    }

    ul, ol {
        list-style: none;
    }

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
    margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        vertical-align: baseline;
    }

    :focus {
        outline: 0;
    }

    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    body {
        line-height: 1;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    .nav-link{
        color: ${theme.color.charcoal};
    }

    .nav-link:hover{
        color: ${theme.color.mainGreen} !important;
        text-decoration: underline;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    textarea {
        overflow: auto;
        vertical-align: top;
        resize: vertical;
    }
    [hidden] {
        display: none;
    }
    html {
        font-size: 100%; /* 1 */
        -webkit-text-size-adjust: 100%; /* 2 */
        -ms-text-size-adjust: 100%; /* 2 */
        overflow-x: hidden;
    }
    a:focus {
        outline: thin dotted;
    }
    a:active,
    a:hover {
        outline: 0;
    }
    img {
        border: 0; /* 1 */
        -ms-interpolation-mode: bicubic; /* 2 */
    }
    figure {
        margin: 0;
    }
    form {
        margin: 0;
    }
    fieldset {
        border: 1px solid #c0c0c0;
        margin: 0 2px;
        padding: 0.35em 0.625em 0.75em;
    }
    legend {
        border: 0; /* 1 */
        padding: 0;
        white-space: normal; /* 2 */
        *margin-left: -7px; /* 3 */
    }
    button,
    input,
    select,
    textarea {
        font-size: 100%; /* 1 */
        margin: 0; /* 2 */
        vertical-align: baseline; /* 3 */
        *vertical-align: middle; /* 3 */
    }
    button,
    input {
        line-height: normal;
    }
    button,
    select {
        text-transform: none;
    }
    button,
    html input[type="button"], /* 1 */
    input[type="reset"],
    input[type="submit"] {
        -webkit-appearance: button; /* 2 */
        cursor: pointer; /* 3 */
        *overflow: visible;  /* 4 */
    }
    button[disabled],
    html input[disabled] {
        cursor: default;
    }
    input[type="checkbox"],
    input[type="radio"] {
        box-sizing: border-box; /* 1 */
        padding: 0; /* 2 */
        *height: 13px; /* 3 */
        *width: 13px; /* 3 */
    }
    input[type="search"] {
        -webkit-appearance: textfield; /* 1 */
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box; /* 2 */
        box-sizing: content-box;
    }
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }
    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
    textarea {
        overflow: auto; /* 1 */
        vertical-align: top; /* 2 */
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    html,
    button,
    input,
    .bg-success{
        background-color: #5A8C4B;
        --bs-bg-opacity: 0.1;
    }
    html{
        background-color: rgba(250, 250, 252,1);
    }
    select,
    textarea {
        color: #222;
    }
    ::-moz-selection {
        background: #b3d4fc;
        text-shadow: none;
    }
    ::selection {
        text-shadow: none;
    }
    img {
        vertical-align: middle;
    }
    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
    }
    textarea {
        resize: vertical;
    }

    html {
        height: 100%;
    }

    #root {
        height: 100%;
    }

    body {
        font-family: ${theme.font.text};
        font-weight: ${theme.font.weight.regular};
        color: ${theme.color.charcoal};
        height: 100%;
        background: ${theme.color.white};
    }
`;
export default MainTheme;
