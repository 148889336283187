import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/organisms/NavigationBar/NavigationBar.component";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../components/atoms/Header/Header.component";
import P from "../../components/atoms/Paragraph/Paragraph.styles";
import HomepageCard from "../../components/molecules/HomepageCard/HomepageCard.component";

const Homepage: React.FC = () => {
  const permissions = localStorage.getItem("permissions");
  const [smallCardData, setSmallCardData] = useState<
    {
      name: string;
      image: string;
      link: string;
      description: string;
      available: boolean;
    }[]
  >([
    {
      name: "Ship Review",
      image: "/images/ship_review.png",
      link: "/ship-review",
      description:
        "Deep insights into entire fleets, custom portfolios, and individual vessels: detailed information on emissions and 55 validated ESG scores for more than 100.000 vessels.",
      available: false,
    },
    {
      name: "ETS Manager",
      image: "/images/ets_manager.png",
      link: "/ets-manager",
      description:
        "End-to-end solution for managing the ETS process between owners, managers, and charterers. Including shipping’s leading EUA trading solution.",
      available: false,
    },
    {
      name: "FuelEU Planner",
      image: "/images/eua_trader.png",
      link: "/eua_trader",
      description:
        "OceanScore’s “trade only” solution. Buying and selling EUAs with shipping’s lowest bid-ask-spreads.",
      available: false,
    },
  ]);

  useEffect(() => {
    setSmallCardData([
      {
        name: "Ship Review",
        image: "/images/ship_review.png",
        link: "/ship-review",
        description:
          "Deep insights into entire fleets, custom portfolios, and individual vessels: detailed information on emissions and 55 validated ESG scores for more than 100.000 vessels.",
        available: permissions?.includes("accounts.ship_review.can_access") ?? false,
      },
      {
        name: "ETS Manager",
        image: "/images/ets_manager.png",
        link: "/ets-manager",
        description:
          "End-to-end solution for managing the ETS process between owners, managers, and charterers. Including shipping’s leading EUA trading solution.",
        available: permissions?.includes("accounts.ets.can_access") ?? false,
      },
      {
        name: "FuelEU Planner",
        image: "/images/fueleu_actions.png",
        link: "/fueleu",
        description:
          "Planning and budgeting tool for FuelEU exposure of fleets, sub-fleets, and pools. Asses and simulate compliance balances and plan settlement actions.",
        available: permissions?.includes("accounts.fueleu.can_access") ?? false,
      },
    ]);
  }, [permissions]);

  return (
    <Container fluid style={{ background: "#fafafc", padding: 0, margin: 0 }}>
      <NavigationBar />
      <Container style={{ minHeight: "100vh", padding: "0 80px" }}>
        <Row style={{ padding: "24px 0" }}>
          <Header size="h2">Products</Header>
        </Row>
        <Row style={{ marginBottom: "40px" }}>
          <P>Access subscribed products or contact us for more product options.</P>
        </Row>
        <div
          style={{
            display: "flex",
            height: "512px",
            padding: 0,
            margin: 0,
            gap: "32px",
          }}
        >
          {smallCardData
            .filter((item) => item.available)
            .map((item, index) => (
              <Col key={index} style={{ height: "100%", padding: 0, margin: 0 }}>
                <HomepageCard
                  name={item.name}
                  image={item.image}
                  link={item.link}
                  description={item.description}
                  available={item.available}
                />
              </Col>
            ))}
          {smallCardData
            .filter((item) => !item.available)
            .map((item, index) => (
              <Col key={index} style={{ height: "100%", padding: 0, margin: 0 }}>
                <HomepageCard
                  name={item.name}
                  image={item.image}
                  link={item.link}
                  description={item.description}
                  available={item.available}
                />
              </Col>
            ))}
        </div>
      </Container>
    </Container>
  );
};

export default Homepage;
