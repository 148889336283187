import React from "react";
import { StyledButton, ButtonComponentInterface } from "./Button.styles";

export interface ButtonInterface extends ButtonComponentInterface {
  children: React.ReactNode | string;
  type?: string;
  className?: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonInterface> = ({
  children,
  variant = "primary",
  type = "button",
  ...args
}) => (
  <StyledButton variant={variant} type={type} {...args}>
    {children}
  </StyledButton>
);

export default React.memo(Button);
