import styled from "styled-components";
import Theme from "../../../themes/MainTheme";
import { ReactComponent as Chevron } from "../../../assets/bg_chevron.svg";
import Header from "../../atoms/Header/Header.component";

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${Theme.color.mainGreen};
`;

export const StyledChevron = styled(Chevron)<{ open: boolean }>`
  color: ${Theme.color.mainGreen};
  transform: ${({ open }) => (open ? "rotate(180deg)" : "none")};
`;

export const StyledHeader = styled(Header)`
  font-size: 18px;
  line-height: 39px;
  margin-bottom: 16px;
`;
