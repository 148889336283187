export const ShipDetails = {
	ShipDetails: {
		width: {
			image: 46.2,
		},
		height: {
			image: '100%',
		},
	},
};

export default ShipDetails;
