export const USER_LOADING = 'USER_LOADING';
export const USER_UNAUTHENTICATED = 'USER_UNAUTHENTICATED';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';

export type ShipType = {
	user: any;
	id?: string;
};

export type UserDispatchTypes =
	| UserLoading
	| UserUnauthenticated
	| UserAuthenticated;

export interface UserLoading {
	type: typeof USER_LOADING;
}

export interface UserUnauthenticated {
	type: typeof USER_UNAUTHENTICATED;
}

// TODO: Change type of payload
export interface UserAuthenticated {
	type: typeof USER_AUTHENTICATED;
	payload: any;
}
