export const routes = {
  homepage: "/",
  landingPage: "/welcome",
  fleet: "/my-fleet",
  scoring: "/check-scorings",
  login: "/login",
  logout: "/logout",
  imprint: "/imprint",
  termsAndConditions: "/terms-and-conditions",
  privacy: "/privacy",
  subscriptionModels: "/subscription-models",
  resetPassword: "/reset-password",
  about: "/about",
  shipManagers: "/ship-managers",
};
