import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { ColorType } from "../../../types/types";
import MainTheme from "../../../themes/MainTheme";

export interface SpinnerInterface {
  isLoading?: boolean;
  size?: number;
  color?: ColorType;
}

const Spinner: React.FC<SpinnerInterface> = ({
  isLoading,
  size = 35,
  color = MainTheme.color.mainGreen,
}) => <ClipLoader size={size} color={color} loading={isLoading} />;

export default Spinner;
