import React from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavbarWrapper } from "./NavigationBar.styles";
import Navbar from "react-bootstrap/Navbar";
import ButtonComponent from "../../atoms/Button/Button.component";

export interface TopbarInterface {
  isLandingPage?: boolean;
  isMaterial?: boolean;
  isInfoPage?: boolean;
  handleToggleForm?: () => void;
}
const NavigationBar: React.FC<TopbarInterface> = ({
  isLandingPage = false,
  isMaterial,
  isInfoPage = false,
  handleToggleForm,
}) => {
  const isAuth = useSelector((state: any) => state.user.isAuthenticated);
  const user = localStorage.getItem("email");
  return (
    <NavbarWrapper bg="white" sticky="top">
      <Container dir="row">
        <Navbar.Brand href="/">
          <img
            src="/images/logotype.svg"
            height="30"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Nav style={{ alignItems: "center", gap: "48px" }}>
          {!isAuth ? (
            <>
              <Nav.Link style={{ padding: 0, color: "#253235" }} href="/login">
                Login
              </Nav.Link>
              <ButtonComponent fontColor="white" onClick={handleToggleForm}>
                Contact us
              </ButtonComponent>
            </>
          ) : (
            <NavDropdown title={user} style={{color: "black"}}>
              <NavDropdown.Item style={{ fontSize: "16px" }} href="/logout">
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Container>
    </NavbarWrapper>
  );
};

export default NavigationBar;
