const ParagraphSizes = {
	ParagraphSizes: {
		extraSmall: 'extraSmall',
		small: 'small',
		regular: 'regular',
		large: 'large',
	},
};

export default ParagraphSizes;
