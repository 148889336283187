const ButtonPartials = {
	ButtonVariantTypes: {
		regular: 17.4,
		small: 16,
		scoringNav: 15,
		navigation: 15,
	},
	ButtonHeightTypes: {
		regular: 5.6,
		navigation: 5.0,
		small: 3.4,
		scoringNav: 5.2,
		scoringItem: 1.02,
	},
};

export default ButtonPartials;
