import React from "react";
import {
  HeaderSizeType,
  TextAlignType,
  TextTransformType,
  ColorType,
  FontWeightType,
  HeaderVariantType,
} from "../../../types/types";
import { StyledTitle } from "./Header.styles";

export interface HeaderInterface {
  size?: HeaderSizeType;
  align?: TextAlignType;
  transform?: TextTransformType;
  color?: ColorType;
  weight?: FontWeightType;
  shadow?: boolean;
  children: React.ReactNode | string;
  variant?: HeaderVariantType;
  ref?: React.RefObject<HTMLHeadingElement>;
}

const Header: React.FC<HeaderInterface> = ({ children, variant, ...args }) => (
  <StyledTitle as={variant} {...args}>
    {children}
  </StyledTitle>
);

export default Header;
