const InputPartials = {
	InputPartials: {
		regular: 5.6,
	},
	InputLeftPadding: {
		regular: 5.7,
		none: 0,
	},
	InputWidth: {
		regular: 49.5,
	},
};

export default InputPartials;
