import styled from "styled-components";
import { HeaderInterface } from "./Header.component";

export const StyledTitle = styled.h1<HeaderInterface> `
  color: ${({ theme, color }) => (color ? theme.color[color] : "inherit")};

  font-size: ${({ theme, size }) => size
    ? `${theme.font.size.title[size]}rem`
    : `${theme.font.size.title.h1}rem`};

  line-height: ${({ theme, size }) => size
    ? `${theme.font.size.title[size] * theme.font.lhRatio.title}rem`
    : `${theme.font.size.title.h1 * theme.font.lhRatio.title}rem`};

  text-transform: ${({ theme, transform }) => transform ? theme.text.transform[transform] : theme.text.transform.left};

  font-weight: ${({ theme, weight }) => weight ? theme.font.weight[weight] : theme.font.weight.bold};

  text-align: ${({ theme, align }) => align ? theme.text.align[align] : theme.text.align.left};

  text-shadow: ${({ theme, shadow }) => shadow ? theme.text.shadow.header : "unset"};
`;
