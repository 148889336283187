import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ButtonComponent from "../../atoms/Button/Button.component";
import {
  StyledHeader,
} from "./HomepageCard.styles";
import P from "../../atoms/Paragraph/Paragraph.styles";

const HomepageCard: React.FC<{
  image?: string;
  description: string;
  link: string;
  name: string;
  available: boolean;
}> = ({image = "", name, link, description, available}) => {
  return (
    <Row style={{height: "100%", padding: 0, margin: 0}}>
      <Accordion
        flush
        style={{
          borderRadius: "16px",
          border: "1px solid #DCDCDC",
          background: `white`,
          padding: 0,
          height: "100%",
        }}
      >
        <Card.Header style={{height: "100%"}}>
          <Row style={{gap: "108px", margin: 0, width: "100%", height: "100%"}}>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "end",
                padding: "24px",
              }}
            >
              <img
                src={image}
                style={{
                  width: "100%",
                  maxHeight: "185px",
                  marginBottom: "24px",
                  borderRadius: "16px",
                }}
              />
              <StyledHeader size="h2">{name}</StyledHeader>
              <P
                style={{
                  overflow: "hidden",
                  WebkitLineClamp: 5,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {description}
              </P>
              <a
                style={{display: "flex", marginTop: "auto", alignSelf: "end"}}
                href={
                  available
                    ? `${link}/`
                    : "https://oceanscore.com/contact"
                }
                target={!available ? "_blank" : "_self"}
                rel={!available ? "noopener noreferrer" : ""}
              >
                <ButtonComponent variant={available ? "primary" : "secondary"}>
                  {available ? "Access" : "Contact us"}
                </ButtonComponent>
              </a>
            </Col>
          </Row>
        </Card.Header>
      </Accordion>
    </Row>
  );
};

export default HomepageCard;
