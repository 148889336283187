import styled from "styled-components";
import {
  ColorType,
  FontWeightType,
  ParagraphSizeType,
  TextAlignType,
} from "../../../types/types";

export interface PInterface {
  color?: ColorType;
  weight?: FontWeightType;
  size?: ParagraphSizeType;
  children?: React.ReactNode | string;
  align?: TextAlignType;
}

const P = styled.p<PInterface>`
  color: ${({ theme, color }) => (color ? theme.color[color] : "inherit")};

  font-weight: ${({ theme, weight }) =>
    weight ? theme.font.weight[weight] : theme.font.weight.regular};

  text-align: ${({ theme, align }) =>
    align ? theme.text.align[align] : theme.text.align.left};

  font-size: ${({ theme, size }) =>
    size
      ? `${theme.font.size.paragraph[size]}rem`
      : `${theme.font.size.paragraph.p3}rem`};

  line-height: ${({ theme, size }) =>
    size
      ? `${theme.font.size.paragraph[size] * theme.font.lhRatio.paragraph}rem`
      : `${theme.font.size.paragraph.p3 * theme.font.lhRatio.paragraph}rem`};
`;

export default P;
